import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center py-3" }
const _hoisted_2 = { class: "btn-toolbar" }
const _hoisted_3 = { class: "table-settings mb-4" }
const _hoisted_4 = { class: "row align-items-center justify-content-between" }
const _hoisted_5 = { class: "col-4 col-md-2 col-xl-1 pl-md-0 text-right" }
const _hoisted_6 = { class: "card card-body border-light shadow-sm table-wrapper table-responsive pt-0" }
const _hoisted_7 = { class: "table table-hover min-h-400" }
const _hoisted_8 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountSelector = _resolveComponent("CountSelector")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CoinTypeRow = _resolveComponent("CoinTypeRow")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_1, [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "d-block" }, [
            _createElementVNode("h2", { class: "h4" }, "All available Coins"),
            _createElementVNode("p", { class: "mb-0" }, "Find below the available coins")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-outline-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
            }, "Reload")
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "col col-md-6 col-lg-3 col-xl-4" }, [
              _createElementVNode("div", { class: "input-group" }, [
                _createElementVNode("span", { class: "input-group-text" }, [
                  _createElementVNode("span", { class: "fas fa-search" })
                ]),
                _createElementVNode("input", {
                  type: "text",
                  class: "form-control",
                  placeholder: "Find Coins",
                  "aria-label": "Find Coins",
                  disabled: ""
                })
              ])
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.data)
                ? (_openBlock(), _createBlock(_component_CountSelector, {
                    key: 0,
                    modelValue: _ctx.data.meta.per_page,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.meta.per_page) = $event)),
                    onUpdated: _ctx.RefreshData,
                    class: "mr-2"
                  }, null, 8, ["modelValue", "onUpdated"]))
                : _createCommentVNode("", true),
              _createVNode(_component_router_link, {
                to: "/coins/create",
                class: "btn btn-dark"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createElementVNode("i", { class: "fas fa-plus" }, null, -1)
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("table", _hoisted_7, [
            _cache[6] || (_cache[6] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "#"),
                _createElementVNode("th", null, "Name"),
                _createElementVNode("th", null, "Code"),
                _createElementVNode("th", null, "Usd Rate"),
                _createElementVNode("th", null, "Action")
              ])
            ], -1)),
            (_ctx.data)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.coinTypes, (dt, index) => {
                    return (_openBlock(), _createBlock(_component_CoinTypeRow, {
                      key: index,
                      dt: dt
                    }, null, 8, ["dt"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.data)
            ? (_openBlock(), _createBlock(_component_Pagination, {
                key: 0,
                modelValue: _ctx.data.meta.page,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.meta.page) = $event)),
                currentPage: _ctx.data.meta.page,
                totalRecord: _ctx.data.meta.count,
                dataCount: _ctx.data.meta.per_page,
                onUpdated: _ctx.RefreshData
              }, null, 8, ["modelValue", "currentPage", "totalRecord", "dataCount", "onUpdated"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}